@layer modules, ui, base;
@layer base {
  .ApprovalPage_loader__K8ty_ {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.ApprovalPage_wrapper__oOn0Z {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  padding: var(--sk-space-24);
}

.ApprovalPage_form__ZGJxt {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_block__3GtfD {
  display: flex;
  flex-direction: column;
  gap: var(--sk-space-24);
}

.ApprovalPage_link__oKiiq {
  font-size: var(--sk-font-size-1);
}

.ApprovalPage_checkbox__pK7Bw {
  margin-bottom: var(--sk-space-64);
}

.ApprovalPage_ctaBlock__SAFpI {
  position: absolute;
  right: var(--sk-space-24);
  bottom: var(--sk-space-24);
  left: var(--sk-space-24);
  text-align: right;
}

.ApprovalPage_button__B80CO {
  width: 100%;
}

@media (min-width: 48rem) {
  .ApprovalPage_form__ZGJxt {
    align-items: center;
    justify-content: center;
    width: 556px;
    margin: 0 auto;
    gap: var(--sk-space-64);
  }

  .ApprovalPage_block__3GtfD {
    padding: var(--sk-space-32);
    border: 2px solid var(--sk-color-grey-100);
    border-radius: var(--sk-radius-16);
  }

  .ApprovalPage_checkbox__pK7Bw {
    margin-bottom: 0;
  }

  .ApprovalPage_ctaBlock__SAFpI {
    position: static;
  }

  .ApprovalPage_button__B80CO {
    width: auto;
  }
}
}
